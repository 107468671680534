import {Suspense, useEffect} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import {modulesPreloads, modulesRoutes, widget} from "../modules";
import AppContext from "./AppContext";
import LoadRouteWrappers from "./LoadRouteWrappers";


function Router({session}) {

    const hooks = modulesPreloads;
    const hooksContext = {wait: [], lang: window.globaLang || 0};

    let width;
    if(window.outerHeight)   width = window.outerWidth;
    else                     width = document.body.clientWidth;

    let hooksPreload = {isMobile: width < 768, session, lang: window.globaLang || 0};
    for(let i=0; i<hooks.length; i++)   {
        hooksPreload = {...hooksPreload, ...hooks[i](hooksContext)};
    }
    if(hooksContext.wait.length !== 0)   return null;

    const layoutWrappers = (route, lang, index=0) => {
        if(!route.layouts[index])       {
            return <Route key={index} index path={route.fullpath[lang]} element={
                <Suspense fallback={<div />}>
                    {route.element}
                </Suspense>
            } />
        }
        if(index === 0) {
            return <Route key={index} path="" element={
                <AppContext.Provider value={{currentRoute: route, ...hooksPreload}}>
                    <LoadRouteWrappers>
                        {route.layouts[index].element}
                    </LoadRouteWrappers>
                </AppContext.Provider>
            }>
                {layoutWrappers(route, lang, index+1)}
            </Route>
        }
        return <Route key={index} path="" element={
            <Suspense fallback={<div />}>
                {route.layouts[index].element}
            </Suspense>
        }>
            {layoutWrappers(route, lang, index+1)}
        </Route>
    }

    const languages = widget("lang.languages");

    return <BrowserRouter>
        <ScrollToTop />
        <Suspense fallback={<div />}>
            <Routes>
                {Object.values(modulesRoutes).map((route, index) => {
                    if(route.page)  {
                        if(!route.page.active) return null;
                        if(route.page.category_id) return null;
                    }
                    return route.path.map((path, lang) => {
                        if(languages && !languages[lang])   {
                            return null;
                        }
                        return layoutWrappers(route, lang);
                    })
                })}
            </Routes>
        </Suspense>
    </BrowserRouter>
}
export default Router;
