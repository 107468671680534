import rsFlag from "./images/rs-flag.png";
import ukFlag from "./images/uk-flag.svg";

export const languages = [
    {
        id: 0,
        short: "RS",
        name: "Srpski",
        value: "/",
        flag: <img src={rsFlag} width={30} alt="RS zastava"/>,
        label: <span><img src={rsFlag} width={20} alt="RS zastava"/> RS </span>
    },
    {
        id: 1,
        short: "EN",
        name: "English",
        value: "/home",
        flag: <img src={ukFlag} width={30} alt="US zastava"/>,
        label: <span><img src={ukFlag} width={20} alt="US zastava"/> EN </span>
    }
];

export const translations = {
    "ne postoji stranica": ["Ne postoji stranica.", "Page doesn't exists."],
    "zatvorite": ["Zatvorite", "Close"],
    "sacuvajte": ["Sačuvajte", "Save"],
    "sacuvano": ["Sačuvano!", "Saved!"],
    "pretraga": ["Pretraga", "Search"],
    "podesavanja": ["Podešavanja", "Settings"],
    "prazna pretraga": ["Prazna pretraga", "Empty search"],
    "currency": ["RSD", "EUR"],
    "nalog": ["Nalog", "Account"],
    "polje": ["Polje", "Field"],
    "tip": ["Tip", "Type"],
    "blokovi": ["Blokovi", "Blocks"],

    "naslovna": ["Naslovna", "Home"],
    "kontakt": ["Kontakt", "Contact"],
    "detaljnije": ["Detaljnije", "Details"],
    "obrisite": ["Obrišite", "Delete"],
    "zelite da obrisete": ["Želite da obrišete?", "Do you want to delete?"],
    "saznaj vise": ["Saznaj više", "Learn more"],
    "pogledajte": ["Pogledajte", "Look inside"],
    "nazad": ["Nazad", "Back"],
    "jezik": ["Jezik", "Language"],

    /* CMS */
    "ulogujte se": ["Ulogujte se", "Login"],
    "registrujte se": ["Registrujte se", "Register"],
    "registrujte nalog": ["Registrujte nalog", "Register account"],
    "zapamti me": ["Zapamti me", "Remember me"],
    "unesite vas email": ["Unesite vaš email", "Enter your email"],
    "posaljite": ["Pošaljite", "Send"],
    "poslat email za reset lozinke": ["Poslat email za reset lozinke.", "Sent email for link reset."],
    "idite na sajt": ["Idite na sajt", "Go to website"],
    "izlogujte se": ["Izlogujte se", "Logout"],
    "zaboravili ste lozinku": ["Zaboravili ste lozinku?", "Forgot your password?"],

    "dodajte administratora": ["Dodajte administratora", "Add administrator"],
    "zelite da obrisete administratora": ["Želite da obrišete administratora?", "Want to delete administrator?"],
    "obrisano": ["Obrisano!", "Deleted!"],
    "ime": ["Ime", "Name"],
    "prezime": ["Prezime", "Lastname"],
    "predmet": ["Predmet", "Subject"],
    "lozinka": ["Lozinka", "Password"],
    "nova lozinka": ["Nova Lozinka", "New Password"],
    "resetujte lozinku": ["Resetujte lozinku", "Reset password"],
    "posaljite link": ["Pošaljite link", "Send link"],
    "poslato": ["Poslato!", "Sent!"],
    "posaljite link za reset lozinke": ["Pošaljite link za reset lozinke na email", "Send link for password reset on email"],
    "zelite da posaljete email": ["Želite da pošaljete email?", "Want to send email?"],

    "sve": ["Sve", "All"],
    "brend": ["Brend", "Brand"],
    "kategorija": ["Kategorija", "Category"],
    "grupa": ["Grupa", "Group"],
    "popust cena": ["Popust cena", "Discount price"],
    "popust": ["Popust", "Discount"],
    "cena za prikaz": ["Cena za prikaz", "Display price"],
    "nije postavljeno": ["nije postavljeno", "not set"],
    "popust pocinje": ["Popust počinje", "Discount starts"],
    "popust se zavrsava": ["Popust se završava", "Discount ends"],
    "slike": ["Slike", "Images"],
    "fajlovi": ["Fajlovi", "Files"],
    "dodajte fajl": ["Dodajte fajl", "Add File"],
    "kliknite da dodate fajl": ["Kliknite da dodate fajl", "Click to add file"],
    "blok pocinje": ["Blok počinje", "Block starts"],
    "blok se zavrsava": ["Blok se završava", "Block ends"],
    "sirina TEXT bloka": ["Širina TEXT bloka", "TEXT block width"],
    "kontakt informacije": ["Kontakt informacije", "Contact informations"],

    "socijalne mreze": ["Socijalne mreže", "Social networks"],
    "dodajte proizvod": ["Dodajte proizvod", "Add new product"],
    "dodajte proizvode": ["Dodajte proizvode", "Add new products"],
    "naziv proizvoda": ["Naziv proizvoda", "Product name"],
    "na vrh": ["Na vrh", "To Top"],
    "deaktivirajte": ["Deaktivirajte", "Deactivate"],
    "aktivirajte": ["Aktivirajte", "Activate"],
    "dodajte vest": ["Dodajte vest", "Add new"],
    "naslov": ["Naslov", "Title"],
    "strana": ["Strana", "Page"],

    "dodajte stranu": ["Dodajte stranu", "Add page"],
    "dodajte podstranu": ["Dodajte podstranu", "Add subpage"],
    "dodajte blok": ["Dodajte blok", "Add block"],
    "dodajte kategoriju": ["Dodajte kategoriju", "Add category"],
    "blok je aktiviran": ["Blok je aktiviran", "Block is activated"],
    "blok je deaktiviran": ["Blok je deaktiviran", "Block is deactivated"],
    "blok nije poceo": ["Blok nije počeo", "Block not started"],
    "blok je istekao": ["Blok je istekao", "Block is expired"],
    "zelite da obrisete blok": ["Želite da obrišete blok?", "Want to delete block?"],
    "zelite da obrisete baner": ["Želite da obrišete baner?", "Want to delete banner?"],
    "broj banera u jednom redu": ["Broj banera u jednom redu", "Number of banners in a row"],
    "ako je prazno, svi baneri su u jednom redu": ["ako je prazno, svi baneri su u jednom redu", "if blank, all banners are in one line"],
    "prikazuj kao carousel": ["Prikazuj kao carousel", "Show as carousel"],
    "prikazuj punom sirinom": ["Prikazuj punom širinom", "Show as full width"],
    "broj proizvoda u jednom redu": ["Broj proizvoda u jednom redu", "Number of products in a row"],
    "broj kategorija u jednom redu": ["Broj kategorija u jednom redu", "Number of categories in a row"],
    "unesite tekst": ["Unesite tekst", "Enter text"],
    "dodajte baner": ["Dodajte baner", "Add banner"],
    "baner nije aktiviran i nece se prikazivati": ["Baner nije aktiviran i nece se prikazivati.", "The banner is not activated and will not be displayed."],
    "mobilni": ["MOBILNI", "MOBILE"],
    "opis slike": ["Opis (alt) slike.", "Image description (alt)."],
    "link slike": ["Link slike.", "Image link."],
    "prikazuj na mobilnom": ["Prikazuj na mobilnom", "Show on mobile."],
    "baner je aktiviran": ["Baner je aktiviran", "Banner is activated"],
    "baner je deaktiviran": ["Baner je deaktiviran", "Banner is deactivated"],
    "otvaraj u novom prozoru": ["Otvaraj u novom prozoru", "Open in new window"],
    "zelite da uklonite sliku": ["Zelite da uklonite sliku?", "Want to remove the image?"],
    "uklonite": ["uklonite", "remove"],
    "kliknite na sliku da promenite": ["Kliknite na sliku da promenite", "Click on the image to change"],

    "otvorite sve": ["Otvorite sve", "Open all"],
    "zatvorite sve": ["Zatvorite sve", "Close all"],
    "zelite da obrisete kategoriju": ["Želite da obrišete kategoriju?", "Do you want to delete the category?"],
    "prevucite da promenite raspored": ["Prevucite da promenite raspored.", "Drag to change the order."],

    "naziv": ["Naziv", "Name"],
    "dodajte": ["Dodajte", "Add"],
    "kategoriju": ["kategoriju", "category"],
    "kategorije": ["Kategorije", "Categories"],

    "brendovi": ["Brendovi", "Brands"],
    "dodajte brend": ["Dodajte brend", "Add brand"],
    "dodajte filter": ["Dodajte filter", "Add filter"],
    "odaberi": ["Odaberi", "Select"],
    "akcija": ["Akcija", "Action"],
    "novo": ["Novo", "New"],

    "osnovno": ["Osnovno", "Informations"],
    "sifra": ["Šifra", "Code"],
    "datum": ["Datum", "Date"],
    "ime i prezime": ["Ime i prezime", "Full name"],
    "telefon": ["Telefon", "Phone"],
    "odgovorite": ["Odgovorite", "Reply"],
    "kuca": ["kuca...", "typing..."],
    "kontakirajte nas": ["Kontakirajte nas", "Contact us"],
    "unesite pitanje": ["Unesite pitanje...", "Insert question..."],
    "proizvod vise nije dostupan": ["proizvod više nije dostupan", "product is removewd"],


    /*
        PUBLIC
     */
    "pogledajte ponudu": ["POGLEDAJTE PONUDU", "SEE THE OFFER"],
    "mapa sajta": ["Mapa sajta", "Site map"],
    "kupovina": ["KUPOVINA", "FAQ"],
    "podrska": ["PODRŠKA", "SUPPORT"],
    "dostava": ["DOSTAVA", "DELIVERY"],
    "omiljeno": ["Omiljeno", "Wishlist"],
    "korpa": ["Korpa", "Basket"],
    "strane": ["Strane", "Pages"],
    "na teritoriji republike srbije": ["Na teritoriji republike Srbije", "On the territory of the Republic of Serbia"],
    "povracaj robe": ["POVRAĆAJ ROBE", "RETURN GOODS"],
    "14 dana od dana prijema": ["14 dana od dana prijema", "14 days from the date of admission"],
    "nema proizvoda": ["Nema proizvoda.", "No products."],
    "sve kategorije": ["Sve kategorije", "All categories"],
    "od": ["od", "of"],
    "proizvoda": ["proizvoda", "products"],
    "sortiraj": ["SORTIRAJ", "SORT"],
    "po relevantnosti": ["Po relevantnosti", "By relevance"],
    "po nazivu": ["Po nazivu", "By name"],
    "filteri": ["Filteri", "Filters"],
    "boja": ["Boja", "Colors"],
    "povezani proizvodi": ["Povezani proizvodi", "Connected products"],
    "poslednje pogledano": ["Poslednje pogledano", "Last viewed"],
    "po ceni rastuce": ["Po ceni rastuće", "By price ascending"],
    "po ceni opadajuce": ["Po ceni opadajuće", "By price descending"],

    "dodajte u korpu": ["Dodajte u korpu", "Add to cart"],
    "dodato u korpu": ["Dodato u korpu", "Added to cart"],
    "korpa je prazna": ["Korpa je prazna", "Cart is empty"],
    "ukupno": ["Ukupno", "Total"],
    "zavrsite porudzbinu": ["Završite porudžbinu", "Finish order"],
    "porudzbine": ["porudzbine", "orders"],
    "pregledi": ["pregledi", "views"],

    "br": ["Br.", "No."],
    "slika": ["Slika", "Image"],
    "artikal": ["Artikal", "Item"],
    "informacije za porucivanje": ["Informacije za poručivanje", "Informations"],
    "obavezna polja": ["Polja označena zvezdicom (*) su obavezna.", "Fields marked with an asterisk (*) are required."],
    "postanski broj": ["Poštanski broj", "Area code"],
    "tabela velicina": ["Tabela veličina", "Size chart"],
    "placanje pouzecem": ["Plaćanje pouzećem", "Cash on delivery"],
    "uplata na tekuci racun": ["Uplata na tekući račun", "Payment to current account"],
    "placanje 1 desc": ["Plaćanje kurirskoj službi prilikom preuzimanja paketa", "Payment to the courier service when picking up the package"],
    "placanje 2 desc": [
        "Nakon što završite proces naručivanja kontaktiraće vas neko od našeg osoblja",
        "Once you have completed the ordering process, you will be contacted by one of our staff"],
    "posalji porudzbinu": ["POŠALJI PORUDŽBINU", "SUBMIT ORDER"],
    "dostava desc": [
        "Dostava se vrši na teritoriji Republike Srbije, preko kurirske službe \"Dexpress\" i naplaćuje se po trenutnom cenovniku ",
        "Delivery is done through the courier service \"Dexpress\""],
    "uspesno ste porucili": ["Uspešno ste poručili!", "You have successfully ordered!"],
    "hvala na poverenju": ["Hvala na poverenju!", "Thank you for your trust!"],
    "cena": ["Cena", "Price"],
    "porucena cena": ["Poručena cena", "Ordered price"],
    "kolicina": ["Količina", "Quantity"],
    "zemlja": ["Zemlja", "Country"],
    "adresa": ["Adresa", "Address"],
    "grad": ["Grad", "City"],
    "dodatna napomena": ["Dodatna napomena", "Extra message"],
    "nacin placanja": ["Način plaćanja", "Payment method"],
    "promo kod": ["Promo kod", "Promo code"],
    "unesite promo kod": ["Unesite promo kod", "Enter promo code"],
    "proverite kod": ["Proverite kod", "Check code"],
    "nije unet promo kod": ["Nije unet promo kod.", "Promo code not entered."],

    "podelite": ["Podelite", "Share"],
    "kontaktirajte nas": ["Kontaktirajte nas", "Contact us"],
    "poruka": ["Poruka", "Message"],
    "obrazac za identifikaciju": ["Obrazac za identifikaciju", "Identification form"],

    // SUPERWEB
    "klijent": ["Klijent", "Client"],
    "tip projekta": ["Tip projekta", "Project type"],
    "web adresa": ["Web adresa", "web address"],
    "podaci o firmi": ["Podaci o firmi", "Company information"],
    "preuzmite": ["Preuzmite", "Download"],
    "contact form": ["Zatražite ponudu, ili zakažite gratis konsultacije...", "Request an offer, or schedule a free consultation ..."],
    "imate na umu projekat": ["Imate na umu projekat?", "Do you have a project in mind?"],
    "ne gubite vreme": [
        "Ne gubite vreme, razgovarajmo bez obaveze, zakažite gratis konsultacije.",
        "Don't waste time, let's talk without obligation, and schedule a free consultation."],
    "pratite nas": ["Pratite nas", "Follow us"],
    "sva prava zadrzana": ["Sva prava zadržana", "All rights reserved"],
    "pratite nas desc": ["Budite u toku \nsa najnovijim sadržajima", "Stay up to date \nwith the latest content"],
};