import {Toaster} from "react-hot-toast";
import Router from "./Router";
import {ApolloProvider} from "@apollo/client";
import {apolloClient} from "./apolloClient";
import {randString} from "../../elements/helpers";


function App() {
    const session = randString(6);
    const client = apolloClient(window.globaLang || 0);

    return (
        <ApolloProvider client={client}>
            <Toaster />

            <Router session={session} />
        </ApolloProvider>
    );
}

export default App;
