
export const productGraphql = {
    price: false,
    qty: false,
    group: false,
    specifications: false,
    connected: true,
    youtube: false,
}
//export const shopUrls = ["prodavnica", "shop"]
export const shopUrls = ["usluge", "services"]
//export const productUrls = ["proizvod", "product"]
export const productUrls = ["portfolio", "portfolios"]