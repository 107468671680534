import {gql} from "@apollo/client";
import {countProducts, loadImage1, loadImage2} from "../config";
import {graphql} from "../../modules";
import {PAGINATOR_INFO_FRAGMENT} from "../../../elements/graphql/paginatorInfo";


export const CATEGORY_FRAGMENT = gql`
    fragment CategoryFragment on Category { 
        id 
        name
        parent_id
        slug
        names
        description
        descriptions
        lordicon
        slugs 
        seo_title
        seo_keywords
        seo_description
        seo_titles
        seo_keywords_all
        seo_descriptions
    }
`;

export const GET_CATEGORIES_QUERY = "getCategories";
export function GET_CATEGORIES()    {
    return gql`
        ${CATEGORY_FRAGMENT}
        ${loadImage1 ? graphql("storage.storage").fragment : ""}
        query ($search: String) {
            getCategories(search: $search) {
                ...CategoryFragment
                ${countProducts ? "products_count" : ""} 
                ${loadImage1 ? graphql("storage.storage", "image").body : ""}
                ${loadImage2 ? graphql("storage.storage", "image_2").body : ""}
            }
        }
    `
}
export const GET_CATEGORIES_WITH_PAGINATION_QUERY = "getCategoriesWithPagination";
export function GET_CATEGORIES_WITH_PAGINATION()    {
    return gql`
        ${CATEGORY_FRAGMENT}
        ${PAGINATOR_INFO_FRAGMENT}
        ${loadImage1 ? graphql("storage.storage").fragment : ""}
        query ($first: Int, $page: Int, $search: String, $order: String, $start_date: String, $end_date: String) {
            getCategoriesWithPagination(first: $first, page: $page, search: $search, order: $order,
                    start_date: $start_date, end_date: $end_date) {
                data {
                    ...CategoryFragment
                    order_products_count
                    order_products_price
                    products_views
                    e_min_price
                    e_max_price
                    avg_price
                    ${loadImage1 ? graphql("storage.storage", "image").body : ""}
                }
                paginatorInfo {
                    ...PaginatorInfoFragment
                }
            }
        }
    `
}



export const UPSERT_CATEGORY = gql` 
    ${CATEGORY_FRAGMENT}
    mutation ($id: ID, $name: String, $slug: String, $names: [String], $slugs: [String], $parent_id: ID, $image_id: ID,
     $seo_title: String, $seo_description: String, $seo_keywords: String, $image_id_2: ID, $description: String)  {
        upsertCategory(id: $id, name: $name, slug: $slug, names: $names, slugs: $slugs, parent_id: $parent_id, image_id: $image_id,
            seo_title: $seo_title, seo_description: $seo_description, seo_keywords: $seo_keywords, image_id_2: $image_id_2,
            description: $description) {
            ...CategoryFragment
        }
    }
`

export const DELETE_CATEGORY = gql`
    ${CATEGORY_FRAGMENT}
    mutation ($id: ID!) {
        deleteCategory(id: $id) {
            ...CategoryFragment
        }
    }
`
