import {lazy} from "react";
import usePreloadCategories from "./preloads/usePreloadCategories";
import {CATEGORY_FRAGMENT} from "./graphql/categories";
const CmsCategories = lazy(() => import("./views/cmsCategories/CmsCategories"));
const ShopCategories = lazy(() => import("./widgets/ShopCategories"));
const CategoriesCarousel = lazy(() => import("./widgets/CategoriesCarousel"));
const CategoriesDrawer = lazy(() => import("./widgets/CategoriesDrawer"));
const CmsCategoriesDrawer = lazy(() => import("./widgets/CmsCategoriesDrawer"));
const CmsProductCategories = lazy(() => import("./widgets/CmsProductCategories"));
const CmsStats = lazy(() => import("./views/cmsStats/CmsStats"));



const exports = {
    routes: [
        {
            name: "cmsCategories",
            trans: ["Kategorije", "Categories"],
            path: ["kategorije", "categories"],
            element: <CmsCategories />,
            layouts: ["cms.CmsLayout"],
            parent: "products.cmsProductsTab",
            menus: {cmsLeft: {position: 2}},
        },
        /*{
            name: "cmsStats",
            trans: ["Statistika (kategorije)", "Statistics (categories)"],
            path: ["proizvodi-statistika-kategorije", "products-statistics-categories"],
            parent: "products.cmsProductsTab",
            element: <CmsStats />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 3}},
        },*/
    ],
    preloads: [usePreloadCategories],
    graphql: {category: CATEGORY_FRAGMENT},
    widgets: {
        CategoriesCarousel: CategoriesCarousel,
        CmsCategoriesDrawer: CmsCategoriesDrawer,
        CategoriesDrawer: CategoriesDrawer,
        CmsProductCategories: CmsProductCategories, // Izlistane Categorje na cms proizvod strani
        ShopCategories: ShopCategories,
    },
}
export default exports;
