import {lazy} from "react";
import usePreloadReadOnly from "./preloads/usePreloadReadOnly";
const RichTextEditor = lazy(() => import("./widgets/Editor/RichTextEditor"));
const ReadOnlyEditor = lazy(() => import(/* webpackChunkName: "ReadOly editor" */ "./widgets/ReadOnlyEditor"));

const exports = {
    preloads: [usePreloadReadOnly],
    widgets: {
        Editor: RichTextEditor,
        ReadOnly: ReadOnlyEditor,
    }
}
export default exports;