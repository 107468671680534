import {useContext} from "react";
import AppContext from "../../../_app/AppContext";
import {widget} from "../../../modules";




function PageTemplate() {
    const {currentRoute, setting} = useContext(AppContext);
    if(currentRoute.page?.block_code)  {
        const welcomePage = currentRoute.fullpath.includes("/");
        return <>
            {!welcomePage && widget("public.Breadcrumbs", {links: [{name: currentRoute.page?.name}]})}
            <div className="my-12 md:my-20">

                {welcomePage &&
                <div className="container mx-auto py-20 relative mb-20">
                    <h1 className="whitespace-pre-wrap text-4xl md:text-7xl font-bold md:ml-6 leading-normal z-10 relative">{setting("header quote")}<span
                        className="text-pubaccent-1">.</span> </h1>

                    <div className="absolute inset-0 flex items-center justify-center opacity-10 dark:opacity-40">
                        <div className="bg-pubaccent-1 w-1/2 md:w-1/4 aspect-square" style={{borderRadius: "30%"}} />
                    </div>

                </div>}

                {widget("design.BlocksRender", {code: currentRoute.page?.block_code, welcomePage})}
            </div>

        </>
    }

    return null
}
export default PageTemplate;