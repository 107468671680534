import {lazy} from "react";
//import useLangMatch from "./preloads/useLangMatch";
import useTransHook from "./preloads/useTransHook";
import useRouteHook from "./preloads/useRouteHook";
import {languages} from "./config";
const Wrapper = lazy(() => import("./widgets/Wrapper"));
const LangSelect = lazy(() => import("./widgets/LangSelect"));

const exports = {
    routes: [

    ],
    preloads: [useTransHook, useRouteHook],
    widgets: {
        LangSelect: LangSelect,
        Wrapper: Wrapper,
        languages: languages
    }
}
export default exports;